/*
 * @description: 
 * @Author: Jay
 * @Date: 2024-03-25 12:04:40
 * @LastEditors: Jay
 * @LastEditTime: 2024-03-25 13:17:06
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
const app = createApp(App)

// element-ui 按需引入
import element from './element'
app.use(element)

// 全局方法
import operate from "./utils/operate"
app.config.globalProperties.$operate = operate;

app.use(store).use(router).mount('#app')
