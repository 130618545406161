/*
 * @description: 
 * @Author: Jay
 * @Date: 2024-03-25 12:04:40
 * @LastEditors: Jay
 * @LastEditTime: 2024-03-25 13:15:14
 */
import { createStore } from 'vuex'

export default createStore({
  state: {
    // 存储token 没登录默认false
    Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
    // 导航栏名字
    navName: ""
  },
  getters: {
  },
  mutations: {
    LOGIN(state, provider) {
      // 存储token
      state.Authorization = provider;
      localStorage.setItem('Authorization', provider);
      // console.log('存储token', state.Authorization)
    },
    LOGOUT(state) {
      state.Authorization = '';
      // 删除指定key本地存储的值
      localStorage.removeItem('Authorization')
    }
  },
  actions: {
  },
  modules: {
  }
})