import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

// 导入 路由
import { main } from "./main";

//判断设备 
// const Device = /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i;
// console.log("设备", Device.test(navigator.userAgent));
// const redirectPath = Device.test(navigator.userAgent) ? "/m-home" : "/p-home";


const routes: Array<RouteRecordRaw> = [
  // 跟目录
  // {
  //   path: "/",
  //   redirect: redirectPath,
  // }
  // {
  //   path: "/logIn",
  //   name: "logIn",
  //   component: () => import('../views/LogIn/LogIn.vue'),
  //   meta: {
  //     title: "登录",
  //     //是否需要登录
  //     requireAuth: false
  //   }
  // }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  //整合路由
  routes: [...routes, ...main],
})


/*
  前置 路由守卫
*/
/* eslint-disable */
router.beforeEach((to, from, next) => {
  /* -----通过本地缓存进行判断,获取指定key本地存储的值进行判断----- */
  if (to.meta.requireAuth) {
    // 获取指定key本地存储的值
    const token = localStorage.getItem('Authorization')
    if (token === null || token === '') {
      //登录弹窗
      console.log("去登录")
    } else {
      next()
    }
  } else {
    next()
  }
})
/* eslint-disable no-new */

/*
  后置 路由守卫
*/
router.afterEach((to: any) => {
  // console.log("后置 路由守卫", to, from)
  //更改每个页面的标题
  document.title = to.meta.title;
})

export default router
