<!--
 * @description: 
 * @Author: Jay
 * @Date: 2024-03-25 12:04:40
 * @LastEditors: Jay
 * @LastEditTime: 2024-03-25 16:00:30
-->
<template>
  <router-view />
</template>

<style lang="scss">
//自定义
@import "../src/assets/css/reset.css";
@import "../src/assets/css/common.css";
@import "../src/assets/css/custom.css";
@import "../src/assets/css/icon.css";

.min-width {
  min-width: 1280px;
}

.margin-auto {
  width: 1280px;
  min-width: 1280px;
  margin: auto auto;
}
</style>
