/*
 * @description: 按需导入 组件  https://element-plus.gitee.io/zh-CN/
 * @Author: Jay
 * @Date: 2023-02-25 11:31:55
 * @LastEditors: Jay
 * @LastEditTime: 2024-03-29 14:41:55
 */
import {
    ElButton, ElInput, ElCarousel, ElCarouselItem, ElImage,
    ElBacktop, ElDialog, ElEmpty, ElIcon, ElDropdownMenu, ElDropdownItem, ElDropdown,
    ElMenu, ElSubMenu, ElMenuItemGroup, ElMenuItem, ElTable, ElTableColumn
} from 'element-plus'
//注意：样式文件需要单独引入
import 'element-plus/dist/index.css'
const element = {
    install: function (Vue: any) {
        //图标
        Vue.use(ElIcon);
        //按钮
        Vue.use(ElButton);
        //输入框
        Vue.use(ElInput);
        //图片
        Vue.use(ElImage);
        // 走马灯
        Vue.use(ElCarousel);
        Vue.use(ElCarouselItem);
        // 回到顶部
        Vue.use(ElBacktop);
        // 对话框
        Vue.use(ElDialog);
        // 空状态
        Vue.use(ElEmpty)
        //下拉菜单
        Vue.use(ElDropdown)
        Vue.use(ElDropdownMenu)
        Vue.use(ElDropdownItem)
        //菜单
        Vue.use(ElMenu)
        Vue.use(ElSubMenu)
        Vue.use(ElMenuItemGroup)
        Vue.use(ElMenuItem)
        //表格
        Vue.use(ElTable)
        Vue.use(ElTableColumn)
    }
}
export default element