/*
 * @description: 主要菜单
 * @Author: Jay
 * @Date: 2023-07-13 11:58:30
 * @LastEditors: Jay
 * @LastEditTime: 2024-03-27 13:42:12
 */

import { RouteRecordRaw } from 'vue-router'

export const main: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "app",
        component: () => import('../views/AppView.vue'),
        redirect: "/home",
        children: [
            {
                path: "/home",
                name: "home",
                component: () => import('../views/HomeView/HomeView.vue'),
                meta: {
                    title: "首页",
                    //是否需要登录
                    requireAuth: false
                }
            }, {
                path: "/routing",
                name: "routing",
                component: () => import('../views/RoutingView/RoutingView.vue'),
                meta: {
                    title: "合规分账",
                    //是否需要登录
                    requireAuth: false
                }
            }, {
                path: "/aggregate",
                name: "aggregate",
                component: () => import('../views/AggregateView/AggregateView.vue'),
                meta: {
                    title: "聚合支付",
                    //是否需要登录
                    requireAuth: false
                }
            }, {
                path: "/dianshanghy",
                name: "dianshanghy",
                component: () => import('../views/DianshanghyView/DianshanghyView.vue'),
                meta: {
                    title: "电商行业",
                    //是否需要登录
                    requireAuth: false
                }
            }, {
                path: "/supplyChain",
                name: "supplyChain",
                component: () => import('../views/SupplyChainView/SupplyChainView.vue'),
                meta: {
                    title: "B2B供应链",
                    //是否需要登录
                    requireAuth: false
                }
            }, {
                path: "/internet",
                name: "internet",
                component: () => import('../views/InternetView/InternetView.vue'),
                meta: {
                    title: "产业互联网",
                    //是否需要登录
                    requireAuth: false
                }
            }, {
                path: "/networkGuide",
                name: "networkGuide",
                component: () => import('../views/NetworkGuideView/NetworkGuideView.vue'),
                meta: {
                    title: "入网指南",
                    //是否需要登录
                    requireAuth: false
                }
            }
        ]
    }
]